"use client";

import Modal, { ModalDialog } from "@molecules/Modal";
import MediaFormSection from "@organisms/MediaFormSection/MediaFormSection";
import dynamic from "next/dynamic";
import { OTPContextProvider } from "./context";
import EmiModalContent from "@organisms/FeesSection/EmiModalContent";
import { IModalActions, ModalTypes } from "@/types/modal";
import { Suspense, useContext, useEffect} from "react";
import { ModalContext } from "@/context/ModalContext"
import { StorageService } from "@/helpers/storage.service";

const LoginModal = dynamic(() => import("@organisms/LoginModal"), {
  ssr: false,
});
const VideoPopup = dynamic(
  () => import("@organisms/VideoPopup/VideoPopup"),
  {
    ssr: false,
  }
);
const ConnectModal = dynamic(
  () => import("@organisms/modal/ConnectModal/ConnectModal"),
  {
    ssr: false,
  }
);
const CallUsModal = dynamic(
  () => import("@organisms/modal/CallUsModal"),
  {
    ssr: false,
  }
);
const DownloadBrochure = dynamic(
  () => import("@organisms/modal/DownloadBrochure"),
  {
    ssr: false,
  }
);

const RegistrationForm = dynamic(
  () =>
    import("@organisms/modal/RegistrationForm/RegistrationForm"),
  {
    ssr: false,
  }
);

const registrationModalData = {
  Heading:
    "To know more about our career services, register now and our team will reach out to you",
  Image: {
    alt: "Registration form",
    enabled: true,
    source: {
      data: {
        attributes: {
          url: "https://amity-strapi-file-uploads.s3.ap-south-1.amazonaws.com/shaking_hand_speaker_f89bb6a967.png",
        },
      },
    },
  },
};

const renderModalContent = ({
  modalType,
  modalData,
  isMobile,
  onClose,
}: {
  modalType: ModalTypes
  modalData: any
  isMobile: boolean
  onClose: any
}) => {
  const {
    url = "",
    programTitle = "",
    associatedPrograms = [],
    isLandingPage = false,
    specializationText,
    Custom10PgType="",
    isNewLeadForm = false
  } = modalData || {}

  switch (modalType) {
    case ModalTypes.VideoPopup:
      return <VideoPopup url={url} />
    case ModalTypes.LoginModal:
      return <LoginModal isMobile={isMobile} />
    case ModalTypes.ConnectModal:
      return (
        <Suspense fallback={null}>
          <ConnectModal
            isMobile={isMobile}
            associatedPrograms={associatedPrograms}
            isLandingPage={isLandingPage}
            isNewLeadForm={isNewLeadForm}
          />
        </Suspense>
      )
    case ModalTypes.DownloadBrochure:
      return (
        <Suspense fallback={null}>
          <DownloadBrochure
            isMobile={isMobile}
            programTitle={programTitle}
            onClose={onClose}
            specializationText={specializationText}
            Custom10PgType={Custom10PgType}
          />
        </Suspense>
      )
    case ModalTypes.CallUsModal:
      return <CallUsModal isMobile={isMobile} />
    case ModalTypes.emiModal:
      return <EmiModalContent Fees={modalData} />
    case ModalTypes.RegistrationForm:
      return (
        <MediaFormSection {...registrationModalData} isMobile={false}>
          <RegistrationForm />
        </MediaFormSection>
      )
  }
}

const CommonModals = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  const { modalState, modalDispatch } = useContext(ModalContext)
  const { isModalOpen, isModalForceClose, modalType, modalData } = modalState;
  const isSessionModalActive = StorageService.get("timeConnectModal")

  useEffect(()=>{
    const handleQueryBasedModal = async () => {
      const getUrlParams = new URLSearchParams(window.location.search);
      const modalId = getUrlParams.get('modalId') || "";
      if (ModalTypes[modalId]) {
        modalDispatch({
          type: IModalActions.OpenModal,
          data: { modalType: ModalTypes[modalId], isModalOpen: true }
        });
      }
    };
    handleQueryBasedModal();
  },[]);

  useEffect(()=>{
    const pathName = window.location.pathname;
    const modalElement = document.querySelector('div#_modal')
    if(!isModalOpen && !isModalForceClose && !isSessionModalActive && !pathName.includes("lp-nw")){
      const timer = setTimeout(() => {
        if(!modalElement.childElementCount){
          modalDispatch({ 
            type:  IModalActions.UpdateModal,
            data: { modalType: ModalTypes.CallUsModal, isModalOpen : true }
          });
          StorageService.set("timeConnectModal", true)
          clearTimeout(timer);
        }
      }, 30000);
      return () => clearTimeout(timer);
    }
  },[isModalForceClose]);

  return isModalOpen ? (
    <OTPContextProvider>
      <Modal modalId={modalType}>
        <ModalDialog
          onClose={() => modalDispatch({ type: IModalActions.CloseModal })}
          modalType={modalType}
          size={modalType === ModalTypes.emiModal && "xl"}
        >
          {renderModalContent({
            modalType,
            modalData,
            isMobile,
            onClose: () => modalDispatch({ type: IModalActions.CloseModal }),
          })}
        </ModalDialog>
      </Modal>
    </OTPContextProvider>
  ) : null
}

export default CommonModals
